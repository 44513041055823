<script setup lang="ts">
import { processPostBody } from '@@/vuecomposables/process_post_or_comment_body'
import emitClickOnTouch from '@@/vuedirectives/emit_click_on_touch'
import { onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    content: string
    darkMode?: boolean | 'auto'
    isTextSelectable?: boolean
  }>(),
  {
    darkMode: 'auto',
    isTextSelectable: false,
  },
)

const vEmitClickOnTouch = emitClickOnTouch

const postBodyRef = ref<HTMLParagraphElement>()
const body = ref<string>(props.content)

const performPostUpdateActions = async (): Promise<void> => {
  if (postBodyRef.value == null) return
  body.value = await processPostBody(props.content, postBodyRef.value)
}

watch(() => props.content, performPostUpdateActions)

onMounted((): void => {
  performPostUpdateActions()
})
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <p
    ref="postBodyRef"
    v-emit-click-on-touch:deep
    dir="auto"
    data-pw="postBody"
    :class="{
      'wish-body-content': true,
      'wish-body-content-light dark:wish-body-content-dark': darkMode === 'auto',
      'wish-body-content-light': darkMode === false,
      'wish-body-content-dark': darkMode === true,
      'break-words': true,
      'whitespace-break-spaces': true,
      'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
      'text-dark-text-100': darkMode === false,
      'text-light-text-100': darkMode === true,
      'select-text': isTextSelectable,
    }"
    @click="$emit('click', $event)"
    @dblclick="$emit('dblclick', $event)"
    @keydown="$emit('keydown', $event)"
    v-html="body"
  />
</template>

<style lang="scss">
.wish-body-content {
  a {
    @apply underline;
    @apply text-current;
    text-underline-position: from-font;
    text-decoration-thickness: from-font;
  }

  blockquote {
    @apply border-solid;
    @apply border-l-4;
    @apply italic;
    @apply px-4;
  }

  mark {
    @apply text-current;
  }

  pre {
    @apply font-mono;
    @apply rounded-lg;
    @apply whitespace-pre-wrap;
    @apply py-1.5;
    @apply px-2;
    @apply -mx-1;
    @apply my-1.5;
    tab-size: 2;
  }

  var {
    @apply font-mono;
    @apply block;
    @apply text-center;
    direction: ltr;
  }

  &.text-14 p {
    min-height: 21px;
  }

  &.text-body p {
    min-height: 24px;
  }

  &.text-body-posts p {
    min-height: 26px;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  ol,
  ul {
    @apply list-outside;
    padding-inline-start: 2em;
    li {
      @apply py-1;
      @apply px-0;
    }
  }

  &.wish-body-content-light {
    blockquote {
      @apply border-dark-text-300;
    }

    mark {
      @apply bg-tangerine-300;
    }
    &[data-color='red'] mark {
      @apply bg-scarlet-300;
    }
    &[data-color='orange'] mark {
      @apply bg-canary-300;
    }
    &[data-color='green'] mark {
      @apply bg-park-300;
    }
    &[data-color='blue'] mark {
      @apply bg-oceanic-300;
    }
    &[data-color='purple'] mark {
      @apply bg-grape-300;
    }

    pre {
      @apply bg-light-ui-300;
    }
    &[data-color='red'] pre {
      @apply bg-scarlet-200;
    }
    &[data-color='orange'] pre {
      @apply bg-canary-200;
    }
    &[data-color='green'] pre {
      @apply bg-park-200;
    }
    &[data-color='blue'] pre {
      @apply bg-oceanic-200;
    }
    &[data-color='purple'] pre {
      @apply bg-grape-200;
    }
  }

  @mixin dark-body {
    blockquote {
      @apply border-light-text-300;
    }

    mark {
      @apply bg-canary-800;
    }
    &[data-color='red'] mark {
      @apply bg-scarlet-700;
    }
    &[data-color='orange'] mark {
      @apply bg-tangerine-700;
    }
    &[data-color='green'] mark {
      @apply bg-park-700;
    }
    &[data-color='blue'] mark {
      @apply bg-oceanic-700;
    }
    &[data-color='purple'] mark {
      @apply bg-grape-700;
    }

    pre {
      @apply bg-dark-ui-200;
    }
    &[data-color='red'] pre {
      @apply bg-scarlet-800;
    }
    &[data-color='orange'] pre {
      @apply bg-tangerine-800;
    }
    &[data-color='green'] pre {
      @apply bg-park-800;
    }
    &[data-color='blue'] pre {
      @apply bg-oceanic-800;
    }
    &[data-color='purple'] pre {
      @apply bg-grape-800;
    }
  }

  &.wish-body-content-dark {
    @include dark-body;
  }

  &.dark\:wish-body-content-dark {
    @media (prefers-color-scheme: dark) {
      @include dark-body;
    }
  }
}
</style>
